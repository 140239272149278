import React from "react";
import { Outlet } from "react-router-dom";

import LayoutEntryFooter from "./parts/LayoutEntryFooter";

function LayoutEntry() {
  return (
    <div className='focusdesk focusdesk-entry'>
      <main className='entry'>
        <Outlet />
      </main>
      <LayoutEntryFooter />
    </div>
  );
}

export default LayoutEntry;
