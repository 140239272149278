import React from "react";

function Home() {
  return (
    <div className='entry-form-page'>
      <div className='logo-container'>
        <img height='24px' width='155px' alt='FocusDesk Logo' src='/images/focusdesk-logo-white.svg' />
      </div>
      <header className='entry-form-header center'>
        <p className='introduction'>
          The fastest way to deliver exceptional customer support. <br /> Coming soon.
        </p>
      </header>
    </div>
  );
}

export default Home;
