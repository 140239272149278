import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Layouts
import LayoutEntry from "./Layouts/Entry/LayoutEntry";
import Home from "./Pages/Home";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<LayoutEntry />}>
          <Route path='/' element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
