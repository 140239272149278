import React from "react";

function LayoutEntryFooter() {
  return (
    <footer className='entry-footer'>
      <p>&copy; Copyright 2023 - FocusDesk</p>
    </footer>
  );
}

export default LayoutEntryFooter;
